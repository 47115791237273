.container {
    flex: 1;
    display: flex;
}

.content {
    position: relative;
    flex: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(../../assets/images/unauthenticated.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.outlet {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    width: 100%;
    background-color: white;
}

.outlet > form {
    width: 100%;
}

.locale {
    position: absolute !important;
    top: 6px !important;
    right: 6px !important;
}

.title {
    margin: 48px;
    font-size: 26px;
    letter-spacing: 2px;
    color: rgb(60, 60, 60);
}

.subtitle {
    font-size: 16px;
}

.link {
    position: absolute;
    top: 32px;
    left: 24px;
}

.linkItem {
    padding: 8px 16px;
    border: 1px solid rgb(60, 60, 60);
    text-transform: uppercase;
    text-decoration: none;
    color: rgb(40, 40, 40);
    /* background-color: rgba(0, 0, 0, 0.3); */
}

.linkItem:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

@media (min-width: 900px) {

    .content {
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }

    .outlet {
        padding: 48px;
        min-width: 400px;
        width: 400px;
        max-width: 400px;
    }
}

@media (min-width: 1536px) {

    .title {
        font-size: 32px;
    }
}