.container {
    min-height: 600px;
    height: 100vh;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    background-color: white;
}

@media screen and (min-width: 1200px) {
    
    .container {
        box-shadow: 0 0 8px gray;
    }
}