.header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: rgb(155, 215, 220);
}

.ellipse {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 85%;
    height: 100%;
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
    background-color: rgb(140, 200, 210);
}

.logo {
    width: auto;
    max-width: 100%;
    max-height: 60px;
    margin: auto;
    cursor: pointer;
}