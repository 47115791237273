.footer {
    font-size: 14px;
    padding: 12px;
    text-align: center;
    background-color: #3c3c3c;
    color: white;
    pointer-events: all;
}

.legalNotice {
    color: white;
    text-decoration: none;
    margin-left: 2px;
}

    .legalNotice:hover {
        text-decoration: underline;
    }