:root {
	--blue-main-color: #005A86;
	--magenta-main-color: #E7307B;
	--yellow-main-color: #CFCF28;
    --warning-main-color: #ed6c02;
	--error-main-color: #d32f2f;
}

* {
	box-sizing: border-box;
}

html, body {
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

form {
	display: flex;
	flex-direction: column;
}

#root {
	overflow-x: hidden;
}

/* --------------------------------------------------------------------- */
/* -------------- REACT INTERNATIONAL PHONE CSS OVERRIDE --------------- */
/* --------------------------------------------------------------------- */

.react-international-phone-country-selector-button {
    height: 40px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
}

.react-international-phone-input {
    width: 100% !important;
    height: 40px !important;
    font-size: 16px !important;
}

.react-international-phone-error .react-international-phone-country-selector-button {
    border: 1px solid #d32f2f;
}

.react-international-phone-error .react-international-phone-input {
    border: 1px solid #d32f2f;
}

/* --------------------------------------------------------------------- */
/* -------------- REACT INTERNATIONAL PHONE CSS OVERRIDE --------------- */
/* --------------------------------------------------------------------- */

.layoutBackground {
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-image: url(assets/images/background.jpeg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.unauthenticated-backdrop {
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
}

.fs14 {
	font-size: 14px;
}

.fw500 {
	font-weight: 500;
}

.taC {
	text-align: center;
}

.back-to-line {
    white-space: pre-wrap;
}

.w-full {
	width: 100%;
}

.table-td {
	padding: 8px;
	border: 1px solid lightgray;
}

.flex-direction-column {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.inline-flex {
	display: flex;
	align-items: center;
}

.text {
	overflow-wrap: break-word;
	word-break: break-word;
    white-space: pre-wrap;
}

.card-container {
	padding: 12px;
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.08);
}

.subtitle {
	font-size: 14px;
	font-weight: 500;
	color: gray;
}

.dialog-title {
	text-align: center;
	margin-bottom: 16px;
	font-weight: 500;
	font-size: 14px;
	text-transform: uppercase;
}

.list-avatar {
	border-color: white;
	border-style: solid;
	border-width: 2px;
}

.pellet {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
	background-color: white;
}

.menu-card {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	padding: 8px;
	background-color: white;
	border: 1px solid lightgray;
	transition: box-shadow 0.2s ease;
	cursor: pointer;
	text-decoration: none;
	color: unset;
	text-align: center;
	font-size: 16px;
	font-family: 'Roboto';
}

.menu-card:hover {
	border: none;
	box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}