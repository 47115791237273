.version {
    position: absolute;
    bottom: 16px;
    right: 16px;
    color: gray;
    font-size: 14px;
    font-weight: 600;
}

@media (min-width: 900px) {
    
    .version {
        left: 16px;
    }
}